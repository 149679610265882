import { getInstanceId, isFreePlan } from "@appsmith/selectors/tenantSelectors";
import { useSelector } from "react-redux";
import { ENTERPRISE_PRICING_PAGE } from "constants/ThirdPartyConstants";
import { useMemo } from "react";
import { getUserSource } from "@appsmith/utils/AnalyticsUtil";

export const useAppsmithEnterpriseLink = (feature: string) => {
  const instanceId = useSelector(getInstanceId);
  const freePlan = useSelector(isFreePlan);
  let source = getUserSource();
  if (source === "ee" && freePlan) {
    source = "ce";
  }
  const constructedUrl = useMemo(() => {
    const url = new URL(ENTERPRISE_PRICING_PAGE);
    if (source) url.searchParams.append("source", source);
    if (instanceId) url.searchParams.append("instance", instanceId);
    if (feature) url.searchParams.append("feature", feature);
    return url.href;
  }, [source, instanceId, feature]);

  return constructedUrl;
};
